import React, { useState } from "react";
import ReactDOM from "react-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";

function Landing({ onSelectChange }) {
  const [selectedOption, setSelectedOption] = useState("");

  return (
    <div
      id='page1'
      className='main'
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        // gap: 10,
      }}
    >
      {/* <h1>Christmas AI</h1> */}
      {/* <p className='subText'>
        This festive season, use this incredible AI-powered tool that transforms
        your space with stunnig Christmas decorations &amp; lighting!
      </p> */}
      {/* <p>Set</p> */}
    </div>
  );
}

export default Landing;
