import React, { useState } from 'react'
import ImageCropper from './ImageCropper'

const ImageUpload = () => {
    const [blob, setBlob] = useState(null)
    const [inputImg, setInputImg] = useState('')
    const [initialImage, setinitialImage] = useState(null);
    const getBlob = (blob) => {
        // pass blob up from the ImageCropper component
        setBlob(blob)
    }

    const onInputChange = (e) => {
        // convert image file to base64 string
        const file = e.target.files[0]
        const reader = new FileReader()

        reader.addEventListener('load', () => {
            setInputImg(reader.result)
        }, false)

        if (file) {
            reader.readAsDataURL(file)
        }
    }

    const toBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = reject;
        });

    const handleSubmitImage = async (e) => {
        const imageFile = new File([blob], "image.png", { type: "image/png" });
        const beforeImage = await toBase64(imageFile);
        if (beforeImage) {
            setinitialImage(beforeImage);
        }

        console.log(blob);
    }


    return (
        <>

            {!!initialImage ? (
                <img
                    src={initialImage}
                    alt='Uploaded Image'
                    style={{
                        maxWidth: "380px",
                        height: "auto",
                    }}
                    className='img-before'
                />
            ) : (
                <>
                    <input
                        type='file'
                        accept='image/*'
                        onChange={onInputChange}
                    />
                    {
                        inputImg && (
                            <ImageCropper
                                getBlob={getBlob}
                                inputImg={inputImg}
                            />
                        )
                    }
                    <a onClick={handleSubmitImage} style={{ position: 'absolute', top: 0, right: 0 }}>Submit</a>
                </>
            )}
        </>
    )
}

export default ImageUpload