import { Route, Routes } from "react-router-dom";
import Layout from "./components/layout/Layout";
import Home from "./pages/Home";
import "../src/assets/scss/main.scss";
import NotFound from "./pages/NotFound";
import "react-image-crop/dist/ReactCrop.css";
import ImageUpload from "./components/ImageUpload";

function App() {
  return (
    <div className='app-back'>
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route index element={<Home />} />
          {/* <Route path='/test' element={<ImageUpload />} /> */}
          {/* <Route path='/about' element={<About />} /> */}
          {/* CATCH ALL */}
          <Route path='*' element={<NotFound />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
