import React from "react";

function Spinner2() {
  return (
    <div className='spinner-2'>
      <div className='spinner'></div>
      <div>Generating....</div>
    </div>
  );
}

export default Spinner2;
