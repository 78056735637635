import axios from "axios";
import React, { useEffect, useState, useRef, useCallback } from "react";
import AppModal from "../components/AppModal";
import ImageCropper from "../components/ImageCropper";
import Landing from "../components/Landing";
import Spinner2 from "../components/Spinner2";
import ModalImage from "react-modal-image";
import SelectOptions from "../components/SelectOptions";
import Webcam from "react-webcam";

const CustomWebcam = ({ onPhotoCapture }) => {
  const webcamRef = useRef(null);
  const [imgSrc, setImgSrc] = useState(null);
  const [isCameraOn, setIsCameraOn] = useState(false);
  const [isCameraError, setisCameraError] = useState(false)
  const activateCamera = () => {

    setIsCameraOn(true);
  };

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot({
      width: 1920,
      height: 1920,
    });
    setImgSrc(imageSrc);
    if (onPhotoCapture && imageSrc) {
      onPhotoCapture(imageSrc);
    }
  }, [onPhotoCapture, webcamRef]);

  const retake = () => {
    setImgSrc(null);
  };

  function checkCameraPermission() {
    navigator.mediaDevices.getUserMedia({ video: true })
      .then(() => {
        // console.log('Camera access allowed');
        setisCameraError(false)
      })
      .catch((error) => {
        if (error.name === 'NotAllowedError' || error.name === 'PermissionDeniedError') {
          setisCameraError(true)
        } else if (error.name === 'NotFoundError' || error.name === 'DevicesNotFoundError') {
          setisCameraError(true)
        } else {
          setisCameraError(true)
        }
      });
  }


  useEffect(() => {
    checkCameraPermission()
  }, [])



  return (
    <div>
      <div className='container'>
        {/* {isCameraOn ? (
          <>
            {imgSrc ? (
              <img
                src={imgSrc}
                alt='webcam'
                style={{ width: "100%", height: "auto" }}
              />
            ) : (
              <Webcam
                height='auto'
                width={300}
                ref={webcamRef}
                screenshotFormat='image/jpeg'
              />
            )}
            <div className='btn-container'>
              {imgSrc ? (
                <button className='next-button-2' onClick={retake}>
                  Retake photo
                </button>
              ) : (
                <button className='next-button-2' onClick={capture}>
                  Capture photo
                </button>
              )}
            </div>
          </>
        ) : (
          <div className='btn-container'>
            <button className='next-button-2' onClick={activateCamera}>
              Activate Camera
            </button>
          </div>
        )} */}

        {isCameraError ? (
          <div className='btn-container'>
            <p >
              Please Activate Camera
            </p>
          </div>
        ) : (
          <>
            {imgSrc ? (
              <img
                src={imgSrc}
                alt='webcam'
                style={{ width: "100%", height: "auto" }}
              />
            ) : (
              <Webcam
                height='auto'
                width={300}
                ref={webcamRef}
                screenshotFormat='image/jpeg'
              />
            )}
            <div className='btn-container'>
              {imgSrc ? (
                <button className='next-button-2' onClick={retake}>
                  Retake photo
                </button>
              ) : (
                <button className='next-button-2' onClick={capture}>
                  Capture photo
                </button>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

function dataURLtoFile(dataurl, filename) {

  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[arr.length - 1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

//Usage example:

function Home() {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);
  const [isModalOpen, setModalOpen] = useState(false);
  const [generatedImage, setGeneratedImage] = useState(null);
  const [isloading, setIsloading] = useState(false);

  const [landingVisible, setlandingVisible] = useState(true);
  const [selectOptionsVisible, setselectOptionsVisible] = useState(false);
  const [uploadFieldVisible, setuploadFieldVisible] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [initialImage, setinitialImage] = useState(null);
  const [errorMsg, seterrorMsg] = useState(null);

  const [showSpinner, setShowSpinner] = useState(true);

  const [profession, setProfession] = useState("");
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true);
  const [area, setArea] = useState("");

  const [blob, setBlob] = useState(null);
  const [inputImg, setInputImg] = useState("");
  const getBlob = (blob) => {
    // pass blob up from the ImageCropper component
    setBlob(blob);
  };

  useEffect(() => {
    // Simulate a delay of 3 seconds (adjust as needed)
    const delay = 2500;

    const timer = setTimeout(() => {
      setShowSpinner(false);
    }, delay);

    return () => clearTimeout(timer);
  }, []);

  const handleResize = () => {
    setIsDesktop(window.innerWidth > 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const showNextSlide = () => {
    // console.log("next cLicked");

    if (landingVisible) {
      setlandingVisible(false);
      setselectOptionsVisible(true);
    } else if (selectOptionsVisible) {
      setselectOptionsVisible(false);
      setuploadFieldVisible(true);
    }
  };

  const handleRefresh = () => {
    setlandingVisible(true);
    setselectOptionsVisible(false);
    setuploadFieldVisible(false);
    setGeneratedImage(null);
    setinitialImage(null);
    setSelectedOption(null);

    // localStorage.clear();
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });

  const onInputChange = (e) => {
    // convert image file to base64 string
    setModalOpen(true);
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.addEventListener(
      "load",
      () => {
        setInputImg(reader.result);
      },
      false
    );

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleSubmitImage = async (e) => {
    const imageFile = new File([blob], "image.png", { type: "image/png" });
    const beforeImage = await toBase64(imageFile);
    if (beforeImage) {
      setinitialImage(beforeImage);
    }
    generateImage(imageFile);
    handleCloseModal();
  };

  const generateImage = async (imageSource) => {
    const apiKey =
      process.env.STABILITY_API_KEY ??
      "sk-2asCVHR02MaycRo9MtOHRW27cTcjCPeS9BguCkoJgr3n8ZYj";

    if (!apiKey) throw new Error("Missing API key.");

    setIsloading(true);
    seterrorMsg(null);

    const payload = {
      text_prompt: `generate an image with ${area} standing in a  ${profession} `,
    };

    const formData = new FormData();
    formData.set("json", JSON.stringify(payload));
    formData.append("input_image", imageSource);

    const response = await fetch(
      "https://api.gooey.ai/v2/FaceInpainting/form/",
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${apiKey}`,
        },
        body: formData,
      }
    );

    if (!response.ok) {
      // throw new Error(response.status);
    }

    const result = await response.json();
    if (response.status == 200) {
      const { output } = result;
      setGeneratedImage(output?.output_images[0]);
    } else {
      const { error } = result?.detail;
      seterrorMsg(error);
      //console.log({ result });
    }
    // console.log(response.status, result);
    setIsloading(false);
  };

  const handleSelectChange = (option) => {
    setSelectedOption(option);
    localStorage.setItem("landingVisible", "false");
  };

  const onSelectChange = (profession, area) => {
    setProfession(profession);
    setArea(area);
    setIsNextButtonDisabled(!profession || !area);
    // console.log({ profession, area });
    // handelSelectValue();
  };

  const imageCaptured = async (photo) => {
    var file = dataURLtoFile(photo, "Upload.jpeg");
    setinitialImage(photo);

    generateImage(file);
  };

  return (
    <div className='home-back'>
      {/* {showSpinner ? (
        <div className='spinner-container'>
          <Spinner />
        </div>
      ) : null} */}

      <AppModal isOpen={isModalOpen} onClose={handleCloseModal}>
        <div
          style={{
            maxWidth: "460px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div className='react-crop-div'>
            {inputImg && (
              <div>
                <ImageCropper getBlob={getBlob} inputImg={inputImg} />
                <button
                  className='btn-before modal-close-button'
                  style={{ zIndex: 100000 }}
                  onClick={handleSubmitImage}
                >
                  Save
                </button>
              </div>
            )}
          </div>
        </div>
      </AppModal>
      <>
        {landingVisible && (
          <div className='hero-text-wrapper hero-text-wrapper-landing'>
            <div className='test'>
              <h2 className='hero-text hero-text-landing'>
                Are you ready to follow <br /> your glow?
              </h2>
              <div className='wrapper-window'>
                <div className='red'></div>
                <div className=''>
                  <div className='round-in'>
                    <Landing onSelectChange={handleSelectChange} />
                  </div>
                </div>
              </div>

              <button
                className='next-button'
                onClick={showNextSlide}
              // disabled={!selectedOption}
              >
                Start
              </button>
            </div>
          </div>
        )}

        {selectOptionsVisible && (
          <div className='hero-text-wrapper hero-text-wrapper-slide2'>
            <h2 className='hero-text'>
              Who do you see yourself as when you follow your glow?
            </h2>
            <div className='wrapper-window wrapper-window-slide2'>
              <div className='red'></div>
              <div className='rectangle-window'>
                <div className='rect-in'>
                  <SelectOptions onSelectChange={onSelectChange} />
                </div>
              </div>
            </div>

            <button
              className='next-button'
              onClick={showNextSlide}
              disabled={isNextButtonDisabled}
            >
              Next
            </button>
          </div>
        )}

        {uploadFieldVisible && (
          <>
            {isloading ? (
              <div className='image-upload-spinner-container'>
                <Spinner2 />
              </div>
            ) : (
              <div className='hero-text-wrapper hero-text-wrapper-slide3'>
                <h2 className='hero-text'>Show us who you are today</h2>
                {generatedImage ? (
                  <div className='last-wrapper' style={{ maxWidth: "860px" }}>
                    <div
                      className='generate-wrapper'
                      style={{ display: "flex", gap: "10px" }}
                    >
                      <div style={{ width: "100%", height: " auto" }}>
                        {!!initialImage && (
                          <ModalImage
                            small={initialImage}
                            large={initialImage}
                            alt='Uploaded Image'
                            style={{
                              maxWidth: "380px",
                              height: "auto",
                              borderRadius: "15px",
                            }}
                            className='img-before'
                          />
                        )}
                        <p className='text-before'>Before</p>
                      </div>
                      <div style={{ width: "100%", height: " auto" }}>
                        <ModalImage
                          small={generatedImage}
                          large={generatedImage}
                          alt='Result Image'
                          style={{
                            maxWidth: "380px",
                            height: "auto",
                            borderRadius: "15px",
                          }}
                          className='img-before'
                        />
                        <p className='text-after'>After</p>
                      </div>
                    </div>
                    <div
                      className='btn-wrapper-download'
                      style={{
                        display: "flex",
                        gap: "10px",
                        marginTop: "20px",
                        flexDirection: "column",
                      }}
                    >
                      {/* <a
                        download='generated_image.png'
                        href={`data:image/png;base64,${generatedImage}`}
                        className='btn-before'
                      >
                        Download
                      </a> */}
                      <p className='text-view'>
                        Tap to view the image. Long press to save.
                      </p>
                      <a onClick={() => handleRefresh()} className='btn-before'>
                        Refresh
                      </a>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className='flex-wrapper'>
                      <div className='wrapper-window wrapper-window-select'>
                        {/* <div className='round-in upload-div'>
                          <input
                            className='custom-file-input'
                            type='file'
                            accept='image/*'
                            style={{ display: "none" }}
                            id='customFileInput'
                            onChange={onInputChange}
                          />
                          <i
                            className='fa-solid fa-image'
                            style={{ color: "white", fontSize: "3rem" }}
                          ></i>
                          <div className='upload-image'>Upload an image</div>
                        </div>
                        <CustomWebcam
                          className='custom-file-input'
                          onPhotoCapture={imageCaptured}
                        /> */}
                        {isDesktop ? (
                          // Render CustomWebcam only on desktop
                          <CustomWebcam
                            className='custom-file-input'
                            onPhotoCapture={imageCaptured}
                          />
                        ) : (
                          // Render file input for mobile
                          <div className='round-in upload-div'>
                            <input
                              className='custom-file-input'
                              type='file'
                              accept='image/*'
                              style={{ display: "none" }}
                              id='customFileInput'
                              onChange={onInputChange}
                            />
                            <i
                              className='fa-solid fa-image'
                              style={{ color: "white", fontSize: "3rem" }}
                            ></i>

                            <div className='upload-image'>Upload an image</div>
                          </div>
                        )}
                      </div>
                      {errorMsg ? (
                        <p className='error-msg'>{errorMsg}. Please retry.</p>
                      ) : null}
                      <p className='upload-image-copy'>
                        Take a photo/upload a selfie. Make sure your full face{" "}
                        <br />
                        and body is visible from the waist up.
                      </p>

                      {isDesktop ? null : (
                        <label
                          htmlFor='customFileInput'
                          style={{
                            cursor: "pointer",
                            fontSize: "22px",
                            zIndex: 1,
                          }}
                          className='next-button'
                        >
                          Choose Image
                        </label>
                      )}

                      {/* <label
                        htmlFor='customFileInput'
                        style={{
                          cursor: "pointer",
                          fontSize: "22px",
                          zIndex: 1,
                        }}
                        className='next-button'
                      >
                        Choose Image
                      </label> */}
                    </div>
                  </>
                )}
              </div>
            )}
          </>
        )}
      </>
    </div>
  );
}

export default Home;
