import React from 'react'
import { Link } from 'react-router-dom'
function Footer() {
    return (
        <footer>

        </footer>
    )
}

export default Footer