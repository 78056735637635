import React from "react";

const AppModal = ({ isOpen, onClose, children }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className='modal-overlay modal'>
      <div className='modal-content'>
        <button className='modal-close-button' onClick={onClose}>
          <span className='btn-before'>X</span>
        </button>
        {children}
      </div>
    </div>
  );
};

export default AppModal;
