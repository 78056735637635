import React, { useState } from "react";
import Select from "react-dropdown-select";

function SelectOptions({ onSelectChange }) {
  const [selectedPair, setSelectedPair] = useState({});

  const options1 = [
    { value: "Film Set", label: "Actor" },
    { value: "Tech Innovation Hub", label: "AI Developer" },
    { value: "Shelter Adoption Center", label: "Animal Rescue Worker" },
    // test
    { value: "Dig Site", label: "Archaeologist" },
    { value: "Office", label: "Architect" },
    { value: "Studio", label: "Artist" },
    { value: "Space Station", label: "Astronaut" },
    { value: "Observatory", label: "Astronomer" },
    { value: "Gymnasium", label: "Athlete" },
    { value: "Library", label: "Author" },
    { value: "Bakery", label: "Baker" },
    { value: "Coffee Shop", label: "Barista" },
    { value: "Bar", label: "Bartender" },
    { value: "Kitchen", label: "Chef" },
    { value: "Crypto Trading Room", label: "Cryptocurrency Trader" },
    { value: "Theatre Stage", label: "Dancer" },
    { value: "Crime Scene", label: "Detective" },
    { value: "Beachside Workspace", label: "Digital Nomad" },
    { value: "Craft Workshop", label: "DIY Craft Blogger" },
    { value: "Home DIY Studio", label: "DIY YouTuber" },
    { value: "Nightclub", label: "DJ" },
    { value: "Doctor's Cabin", label: "Doctor" },
    { value: "Aerial Photography Studio", label: "Drone Photographer" },
    { value: "Aerial Observation Deck", label: "Drone Pilot" },
    { value: "Home Warehouse", label: "Ecommerce Entrepreneur" },
    { value: "Electrical Room", label: "Electrician" },
    { value: "Workshop", label: "Engineer" },
    { value: "Wilderness Conservation Site", label: "Environmentalist" },
    { value: "Sustainable Fashion Studio", label: "Ethical Fashion Designer" },
    { value: "Exotic Pet Sanctuary", label: "Exotic Pet Breeder" },
    { value: "Field", label: "Farmer" },
    { value: "Runway", label: "Fashion Designer" },
    { value: "Fire Station", label: "Firefighter" },
    { value: "Dock", label: "Fisherman" },
    { value: "Outdoor Fitness Park", label: "Fitness Trainer" },
    { value: "Flower Shop", label: "Florist" },
    { value: "Home Kitchen Studio", label: "Food Blogger" },
    { value: "Culinary Photoshoot Studio", label: "Food Stylist" },
    { value: "Gaming Lounge", label: "Game Developer" },
    { value: "Garden", label: "Gardener" },
    { value: "Geological Site", label: "Geologist" },
    { value: "Creative Loft", label: "Graphic Designer" },
    { value: "Wellness Retreat Center", label: "Health Coach" },
    { value: "Server Room", label: "IT Professional" },
    { value: "Bookstore", label: "Librarian" },
    { value: "Court Room", label: "Lawyer" },
    { value: "Magic Show Stage", label: "Magician" },
    { value: "Beauty Salon", label: "Makeup Artist" },
    { value: "Aquarium", label: "Marine Biologist" },
    { value: "Dojo", label: "Martial Arts Instructor" },
    { value: "Zen Garden", label: "Meditation Instructor" },
    { value: "Alpine Lodge", label: "Mountain Guide" },
    { value: "Recording studio", label: "Musician" },
    { value: "Newsroom", label: "News Reporter" },
    { value: "Mountain Basecamp", label: "Outdoor Adventure Guide" },
    { value: "Scenic Venue", label: "Outdoor Wedding Photographer" },
    { value: "Fragrance Lab", label: "Perfumer" },
    { value: "Fashion Boutique", label: "Personal Stylist" },
    { value: "Darkroom", label: "Photographer" },
    { value: "Cockpit", label: "Pilot" },
    { value: "Plumbing Workshop", label: "Plumber" },
    { value: "Recording Studio", label: "Podcast Host" },
    { value: "Crime scene", label: "Police" },
    { value: "Classroom", label: "Professor" },
    { value: "Futuristic Writing Nook", label: "Science Fiction Author" },
    { value: "Laboratory", label: "Scientist" },
    { value: "Theatre Stage ", label: "Singer" },
    { value: "Ski Resort", label: "Ski Instructor" },
    { value: "Content Creation Studio", label: "Social Media Influencer" },
    { value: "Home Office", label: "Startup Owner" },
    { value: "Operating Room", label: "Surgeon" },
    { value: "Community Garden", label: "Sustainable Agriculture Advocate" },
    { value: "Eco Friendly Building Site", label: "Sustainable Architect" },
    { value: "Solar Farm", label: "Sustainable Energy Engineer" },
    { value: "Upcycling Workshop", label: "Sustainable Product Designer" },
    { value: "Army Camp", label: "Soldier" },
    { value: "Co-working Space", label: "Tech Entrepreneur" },
    { value: "Tourist Attraction", label: "Tour Guide" },
    { value: "Backpacking Base", label: "Travel Blogger" },
    { value: "classroom", label: "Teacher" },
    { value: "Abandoned Site", label: "Urban Explorer" },
    { value: "Rooftop Farm", label: "Urban Farmer" },
    { value: "Veterinary Clinic", label: "Veterinarian" },
    { value: "Antique Store", label: "Vintage Collector" },
    { value: "Virtual Reality Lab", label: "Virtual Reality Developer" },
    { value: "Coding Workspace", label: "Web Developer" },
    { value: "Event Planning Studio", label: "Wedding Planner" },
    { value: "Safari Basecamp", label: "Wildlife Photographer" },
    { value: "Yoga Studio", label: "Yoga Instructor" },
    { value: "Zoo", label: "Zookeeper" },
  ];
  return (
    <div>
      <div className='select-wrapper'>
        <div className='select-copy'>
          <div className='select-label'>
            <p className='select-label'>I see myself as a</p>
          </div>
          <div className='select-item'>
            <Select
              className='option-font'
              options={options1}
              onChange={(value) => {
                onSelectChange(value[0]?.value, value[0]?.label);
                setSelectedPair(value[0]);
              }}
              multi={false}
              clearOnSelect={true}
              keepSelectedInList={false}
              dropdownGap={0}
              placeholder='Select'
            />
          </div>
        </div>
        <div className='select-copy'>
          <div className='select-label'>
            <p className='select-label'>Standing in a</p>
          </div>
          <div className='select-item select-item-2'>
            <p>{selectedPair ? selectedPair.value : null}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SelectOptions;
